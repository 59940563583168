.validation {
  font-size: 1.1rem;
  margin: 0.5rem auto;
  font-weight: bold;
  text-align: left;
  &.--note {
    color: var(--color-dark-text);
  }
  &.--warning {
    color: var(--color-dark-text);
    padding: 1rem;
    border-radius: 15px;
    background-color: var(--color-secondary);
  }
  &.--error {
    color: var(--color-primary);
  }
  &.--light {
    color: var(--color-light-text);
  }
}
