
.Loading {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: all 200ms ease;
  background-color: var(--color-primary);
  
  & .__spinner {
    transition: all 200ms ease;
    color: var(--color-dark-secondary);
    font-size: 6rem;
    animation-name: spinner;
    animation-duration: 1.2s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
  }
}

