.maintenance {
  width: 100vw;
  height: 100vh;
  font-size: 10rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .__message {
      margin: 3rem;
      font-size: 2rem;
      font-weight: bolder;
  }
}
