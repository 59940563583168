@keyframes flash {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes spinner {
  0% {
    transform: translateY(0%);
  }

  25% {
    transform: translateY(20%);
  }

  75% {
    transform: translateY(-20%);
  }
  
  100% {
    transform: translateY(0%);
  }
}
@keyframes spinner-3 {
  0% {
    color:var(--color-primary);
    transform: translateY(0%);
  }
  
  
  100% {
    transform: translateY(0%);
    color: var(--color-dark-primary);
  }
}
@keyframes spinner-2 {
  0% {
    transform: translateY(0%);
  }

  25% {
    transform: translateY(5%);
  }

  75% {
    transform: translateY(-5%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes marquee {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

