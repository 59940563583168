@mixin orderItemsTables {
  .__gifts {
    width: 100%;
    border: 1px solid var(--color-primary);
    border-radius: 15px;
    li {
      font-weight: bold;
      margin: 0.5rem;
      display: block;
    }
  }

  .__items {
    margin-top: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  table {
    max-width: 100%;
    margin: 1rem;
    td {
      text-align: center;
      border: 1px solid #000;
      padding: 0.5rem;
    }
    td:first-child {
      width: 7rem;
      background-color: var(--color-primary);
      color: var(--color-light-text);
      max-width: 100%;
    }
    td:last-child {
      width: 15rem;
      max-width: 100%;
    }
    @media screen and (max-width: 250px) {
      tr {
        display: flex;
        flex-direction: column;
        td {
          width: 100% !important;
        }
      }
    }
  }
}

@mixin boxBackground {
  background: linear-gradient(
    191deg,
    var(--color-secondary) 0%,
    var(--color-light-secondary) 100%
  );
}

@mixin checkoutBox {
  @include boxBackground;
  font-size: 1.1rem;
  width: 600px;
  max-width: 100%;
  padding: 1rem;
  border: 2px solid var(--color-primary);
  border-radius: 15px;
  box-shadow: -1px 5px 10px -7px #000000;
}
@mixin flex(
  $direction: row,
  $justify-content: center,
  $align-items: center,
  $wrap: wrap,
  $gap: unset
) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify-content;
  align-items: $align-items;
  flex-wrap: $wrap;
  gap: $gap;
}

@mixin container {
  padding: 2rem;
  min-height: 80vh;
}

@mixin fadeIn {
  animation-name: fadeIn;
  animation-fill-mode: both;
  animation-duration: 0.5s;
}
@mixin fadeOut {
  animation-name: fadeOut;
  animation-fill-mode: both;
  animation-duration: 0.5s;
}
@mixin bounceIn {
  animation-name: bounceIn;
  animation-fill-mode: both;
  animation-duration: 0.5s;
}
@mixin offersSliderAnimation {
  animation-name: fadeIn;
  animation-fill-mode: both;
  animation-duration: 1s;
}
@mixin bounceOut {
  animation-name: bounceOut;
  animation-fill-mode: both;
  animation-duration: 0.5s;
}
@mixin slideUp {
  animation-name: slideInUp;
  animation-fill-mode: both;
  animation-duration: 0.5s;
}
@mixin pulse {
  animation-name: pulse;
  animation-fill-mode: both;
  animation-duration: 1s;
  animation-iteration-count: 6;
}

@mixin flash {
  animation: flash 1.52s linear 0s infinite alternate forwards;
}
@mixin marquee {
  // animation: marquee 10s linear 0s infinite alternate;
}
@mixin spin {
  animation-name: spinner-2;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
}

@mixin checkbox-with-icon {
  width: 10rem;
  padding: 1rem;
  text-align: center;
  border: 1px solid var(--color-primary);
  border-radius: 15px;
  color: var(--color-dark-text);
  margin: 0.5rem;
  transition: all 200ms ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .__icon {
    width: 3rem;
    height: 3rem;
  }
  &:hover {
    box-shadow: -1px 5px 10px -7px #000000;
    filter: brightness(80%);
  }
  &.--selected {
    background-color: var(--color-primary);
    color: var(--color-light-text);
    > .__icon {
      color: var(--color-light-text);
      @include spin;
    }
  }
}

@mixin success_after_submit {
  @include fadeIn;
  min-height: 80vh;
 
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  font-weight: bold;
  text-align: center;
  color: var(--color-dark-text);
  * {
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
  }

  .__tick {
    width: 8rem;
    height: 8rem;
    color: var(--color-primary);
  }
}
