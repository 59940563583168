.reviews-page {
  @include flex(column, flex-start, $wrap: nowrap);

  width: 95%;
  min-height: 90vh;

  margin: 0 auto;
  padding: 3rem 2rem 5rem;
  .__login {
    margin-bottom: 2rem;
    .btn,
    .validation {
      font-size: 1.5rem !important;
    }
  }
  .__no_reviews {
    font-size: 2rem;
    text-align: center;
  }
  .__page-title {
    margin-bottom: 2rem;

    font-size: 3rem;
    text-align: center;
  }
  .create-review-container {
    width: min(50%, 600px);

    margin-bottom: 3rem;
    padding: 1rem;

    border: 1px solid var(--color-primary);
    border-radius: 15px;

    @include boxBackground;

    .__title {
      margin-bottom: 0.5rem;
    }

    .__input-container {
      width: 100%;

      margin-bottom: 1rem;
      padding-bottom: 0.5rem;

      border-bottom: 1px solid var(--color-primary);
    }
    .__review-form {
      @include flex(column, flex-start, center, nowrap);

      .input {
        width: 100%;
        min-width: 100%;
        margin-bottom: 0.4rem;

        textarea {
          min-height: 7rem;
          margin-bottom: unset;
        }
      }

      .__anonymous-check {
        @include flex(row, flex-start, center, nowrap);
        width: 100%;

        margin-bottom: 0.5rem;

        input {
          margin-right: 0.5rem;
        }
      }

      .__form-stars {
        @include flex(row, space-around, center, nowrap);

        width: 100%;

        margin-bottom: 1rem;
        padding-bottom: 1rem;

        border-bottom: 1px solid var(--color-primary);

        > * {
          width: 100%;
          text-align: center;
        }

        .__review-type {
          margin-bottom: 0.5rem;
        }

        .__review-value {
          margin: 0 auto;
          span {
            font-size: 1.8rem !important;
          }
        }
        @media screen and (max-width: 900px) {
          flex-direction: column;

          .__pair {
            @include flex(row, space-between, center, nowrap);
            .__review-type {
              margin-bottom: unset;
            }
            margin-bottom: 0.5rem;
          }
        }
      }

      .btn {
        // Width and white-space is defined like this instead of using "width: max-content;" as a fallback of IE
        width: auto;

        padding: 0.5rem 1rem;

        color: var(--color-light-text);

        border-radius: 5px;

        background-color: var(--color-buttons);

        white-space: nowrap;
      }
    }

    @media screen and (max-width: 700px) {
      width: 90%;
    }
  }

  .__reviews-container {
    width: min(90%, 1300px);
  }
  .review-submited {
    @include success_after_submit;
    min-height: unset;
    .validation {
      font-size: 1.5rem;
    }
  }

  @media screen and (max-width: 350px) {
    width: 100%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.user-review {
  width: 100%;
  min-height: 7rem;

  margin-bottom: 2rem;
  padding: 1rem 2rem;

  border: 2px solid var(--color-primary);
  border-radius: 10px;

  background-image: linear-gradient(
    90deg,
    var(--color-secondary),
    var(--color-dark-secondary)
  );

  .__title {
    // Width and white-space is defined like this instead of using "width: max-content;" as a fallback of IE
    width: auto;

    margin-bottom: 0.5rem;

    font-size: 1.5rem;

    white-space: nowrap;
  }

  .__review-container {
    @include flex($justify-content: flex-start, $wrap: nowrap);

    min-height: 2rem;

    padding: 0.5rem 0;
    padding-left: 1rem;

    border-left: 5px solid var(--color-primary);

    @media screen and (max-width: 910px) {
      flex-direction: column-reverse;

      border: unset;

      padding-left: unset;
    }

    .__text {
      width: 100%;
      margin-right: 1rem;
      &::before {
        content: open-quote;
        font-size: 1.5em;
        margin-right: 0.2em;
      }
      &::after {
        content: close-quote;
        font-size: 1.5em;
        margin-left: 0.2em;
      }
      @media screen and (max-width: 910px) {
        width: 100%;
        text-align: center;
      }
    }
    .__stars-container {
      @include flex(column, space-between, $wrap: nowrap, $gap: 0.5rem);
      width: 50%;

      padding-left: 1rem;

      border-left: 2px solid var(--color-dark-primary);

      .__pair {
        @include flex(row, space-between, center, nowrap);
        width: 100%;
        > * {
          width: 100% !important;
        }

        .__review-value {
          margin: 0 auto;

          span {
            font-size: 1.5rem !important;
          }
        }
        @media screen and (max-width: 940px) {
          justify-content: space-between;

          > * {
            width: 100% !important;
          }
        }
      }

      @media screen and (max-width: 910px) {
        width: 100%;

        margin-bottom: 2rem;
        padding-left: unset;
        padding-bottom: 0.5rem;

        border-left: unset;
        border-bottom: 1px solid var(--color-dark-primary);
      }
    }
  }
}

.review-stats {
  width: 100%;
  height: 10rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 1.5rem;
  .__stat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    height: 11rem;
    width: 11rem;
    padding: 2rem;
    text-align: center;
    border: 2px solid var(--color-primary);
    background-color: var(--color-dark-primary);
    color: var(--color-light-text);
    border-radius: 50%;
    font-weight: bold;
    @include fadeIn;

    .__value {
      margin-bottom: 0.5rem;
      font-size: 3rem;
      color: var(--color-dark-secondary);
    }
    @media screen and (max-width: 550px) {
      height: 7.5rem;
      width: 7.5rem;
      font-size: 0.8rem;
      .__value {
        font-size: 1.5rem;
      }
    }
  }
}
