div[class^="HeroCarousel"] {
  @media screen and (max-height: 600px) {
    height: 600px !important;
  }
}

.hero {
  --height: calc(100vh - 4rem);
  .__container {
    background: #0c0c0c;
    display: flex;
    padding: 0 30px;
    height: var(--height);
    position: relative;
    z-index: 1;

    @media screen and (max-height: 600px) {
      height: 600px;
    }
  }
  .__bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: var(--height);
    overflow: hidden;
    z-index: 1;
    @media screen and (max-height: 600px) {
      height: 600px;
    }
  }
  .__img {
    width: 100%;
    height: var(--height);
    background-size: cover;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
    transition: filter 0.5s ease;
    @media screen and (max-height: 600px) {
      height: 600px;
    }

    @media screen and (max-width: 920px) {
      filter: brightness(50%);
    }
    @media screen and (max-height: 600px) {
      filter: brightness(50%);
    }
  }

  .__info {
    color: var(--color-light-text);
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    min-width: 100%;
  }

  .__row {
    display: flex;
    align-items: center;
    flex-direction: row;
    min-width: 100%;
    @media screen and (max-width: 920px) {
      position: relative;

      flex-direction: column-reverse;
      justify-content: center;
    }
  }
}
.__column {
  max-width: 50%;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 920px) {
    max-height: 100%;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
  @media screen and (max-height: 600px) {
    max-height: 100%;
    max-width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  .__text-wrapper {
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 50px;

    padding: 3rem;

    @media screen and (max-width: 920px) {
      align-items: center;
      text-align: center;
      background: unset;
      border-radius: unset;
      padding: unset;
    }

    @media screen and (max-height: 600px) {
      align-items: center;
      text-align: center;
      background: unset;
      border-radius: unset;
    }
  }

  .__top-line {
    color: var(--color-secondary);
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 700;
    letter-spacing: 1.4px;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  .__heading {
    margin-bottom: 1.5rem;
    font-size: 2rem;
    line-height: 1.1;
    font-weight: 600;
    color: var(--color-light-text);
    @media screen and (max-height: 440px) {
      margin-bottom: 1rem;
      font-size: 1.8rem;
    }
  }

  .__subtitle {
    max-width: 100%;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    color: "#a9b3c1";
  }

  .__logo {
    max-width: 10rem;
    margin: 0 auto;
    @media screen and (max-height: 600px) {
      padding: 0;
    }
  }
}

.app-stores-container {
  color: var(--color-light-text);

  .__app-stores {
    @include flex(row, flex-start, center, wrap);
    width: max-content;
    gap: 1rem;
    border-radius: 10px;
    font-size: 1rem;
    @media screen and (max-height: 400px) {
      .__title {
        display: none;
      }
    }

    @media screen and (max-width: 920px) {
      flex-direction: row;

      margin: unset;
      padding: unset;
      background: unset;

      .__title {
        display: none;
      }
    }

    .__title {
      font-size: 0.9rem;
    }

    .__store {
      min-width: 8rem;
      padding: 0.5rem;
      font-size: 0.9rem;
      background-color: var(--color-light-background);
      
      border-radius: 5px;
      &:last-child {
        margin-right: unset;
      }
      
      a {
        @include flex(row, flex-start, center, nowrap);
        color: var(--color-primary);
        text-decoration: none;
        @media screen and (max-width: 920px) {
          justify-content: center;
        }
        p {
          margin-left: 0.5rem;
        }
      }
      @media screen and (max-width: 350px) {
        min-width: 7rem;
        a {
          p {
            display: none;
          }
        }
      }
    }
    
  }
}
.CookieConsent {
  div {

    font-size: 0.9rem;
  }
}
.close_icon{

  button {
    color: white !important;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.5rem;
    background: transparent !important;
    font-size: 2rem;
  }
  
}
