.home {
  @include fadeIn;
  background-image: linear-gradient(
    var(--color-dark-secondary),
    var(--color-secondary),
    var(--color-light-secondary)
  );
  > * {
    @include fadeIn;
  }
  .reservation {
    padding: 0;
    form {
      background: transparent;
      width: 500px;
    }
  }
  .reviews-page {
    padding: 0;
    .create-review-container {
      background: transparent;
    }
  }
}

.info-container {
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .social-links {
    position: static;
    .__link {
      background-color: transparent;
      margin: 1.8rem;
      transition: all 200ms ease;
      &:hover {
        transform: scale(1.2);
      }
      a {
        font-size: 2rem;
      }
    }
  }
  align-items: center;
  text-transform: uppercase;

  & .__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;

    & .__logo {
      max-width: 15rem;
    }

    & .__title {
      padding: 0 0.5rem;
      font-size: 2rem;
      margin-bottom: 0.3rem;
      text-align: center;
    }

    & .__slogan {
      padding: 0 0.5rem;
      margin: 0 0.7rem;
      font-size: 1.1rem;
      color: var(--color-dark-text);
      letter-spacing: 0.3rem;
      word-spacing: 0.1rem;
      margin-bottom: 1rem;
      text-align: center;
    }
    & .__line {
      border-bottom: 3px solid var(--color-primary);
      width: 60%;
    }
  }

  & .__container {
    display: flex;
    flex-direction: row;
    min-width: 70%;
    justify-content: space-around;
    margin-bottom: 2rem;

    @media screen and (max-width: 1000px) {
      min-width: 100%;
    }
    @media screen and (max-width: 641px) {
      flex-direction: column;
      justify-items: center;
      justify-content: center;
    }

    & .__info-item {
      display: flex;
      flex-direction: row;
      font-size: 2rem;
      align-items: center;

      @media screen and (max-width: 641px) {
        margin-bottom: 0.5rem;
        flex-direction: column;
      }

      & .__info-icon {
        font-size: 2.2rem;
        margin-right: 0.3rem;
        border-radius: 50%;
        padding: 0.6rem;
        display: flex;
        align-items: center;
        background-color: var(--color-primary);
        color: var(--color-secondary);
        @media screen and (max-width: 641px) {
          margin-bottom: 0.3rem;
        }
      }

      & .__text {
        line-height: 1.1rem;
        display: flex;
        flex-direction: column;
        font-size: 1rem;
        justify-content: space-around;
        min-height: 70%;

        @media screen and (max-width: 641px) {
          margin-bottom: 0.3rem;
          justify-items: center;
          justify-content: center;
          gap: 0.1rem;
        }

        & .__up-text {
          font-size: 0.7rem;
          letter-spacing: 0.1rem;

          @media screen and (max-width: 641px) {
            text-align: center;
            letter-spacing: 0;
            font-size: 0.9rem;
          }
        }
        & .__down-text {
          color: var(--color-dark-text);
          transition: all 0.2s ease-in-out;
          &:hover {
            color: var(--color-primary);
            text-decoration: underline;
          }
          @media screen and (max-width: 641px) {
            text-align: center;
          }
        }
      }
    }
  }
}

.work-info {
  padding-top: 2rem;
  min-height: 300px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .__hours-wrapper {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
  }
  & .__table {
    width: 900px;
    max-width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--color-primary);
    border-radius: 10px;
    margin-bottom: 4rem;
    & .__table-header {
      width: 100%;
      display: flex;
      text-transform: uppercase;
      color: var(--color-light-text);
      min-height: 1.6rem;
      background-color: var(--color-primary);
      @media screen and (max-width: 768px) {
        min-height: 2rem;
      }
      & .__table-data {
        text-transform: uppercase;
        padding: 1rem;
        @media screen and (max-width: 350px) {
          font-size: 0.7rem;
        }
      }
    }

    & .__table-body {
      width: 100%;

      & .__table-row {
        display: flex;
        text-transform: uppercase;
        border-bottom: 1px solid var(--color-primary);
        min-height: 1.6rem;

        @media screen and (max-width: 768px) {
          min-height: 2rem;
        }

        &:last-child {
          border-bottom: unset;
        }
      }
    }
    & .__table-data {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 2rem;
      width: 100%;
      gap: 0.2rem;
    }
    font-size: 1rem;
    * {
      word-wrap: break-word;
    }
    @media screen and (max-width: 768px) {
      font-size: 0.9rem;
    }
  }

  & .__delivery {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  & .__heading {
    font-size: 2rem;
    margin-bottom: 0.2rem;
    text-transform: uppercase;
    text-align: center;
  }

  & .__sub {
    font-size: small;
    margin-bottom: 1rem;
  }
  & .__opening {
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .__table-data {
      justify-content: center;
      padding: 1rem;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }
  }

  & .__table-header {
    & > * {
      width: 100%;
    }
  }
  & .__table-row {
    & > * {
      width: 100%;
    }
  }
}

.offers-container {
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 1000px;
  max-width: 95%;
  margin: 0 auto;
  padding-bottom: 4rem;

  .__offers-title {
    width: 100%;

    margin-bottom: 2rem;

    text-align: center;
    font-size: 4rem;
  }

  .__offer {
    height: 35rem;
    overflow: hidden;
    margin: 0 auto;
    background-color: var(--color-offers-background);
    border: 2px solid var(--color-dark-secondary);
    border-radius: 15px;
    @media screen and (max-width: 770px) {
      height: unset;
      min-height: 40rem;
    }

    .__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      display: none;
      @media screen and (max-width: 770px) {
        flex-direction: column;
        justify-content: flex-start;
      }
      .__img {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        @include offersSliderAnimation;
        img {
          object-fit: cover;
          object-position: center;
          width: 100%;
          height: 100%;
        }
      }

      .__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        padding: 2rem;
        border-radius: 20px;
        color: var(--color-light-text);
        z-index: 1;
        height: 100%;

        @media screen and (max-width: 770px) {
          max-width: unset;
          width: 100%;
          height: unset;
          color: var(--color-light-text);
          margin-top: 1rem;
        }

        .__arrows {
          z-index: 100;
          margin-top: auto;
          > * {
            color: var(--color-light-text);
            font-size: 2.5rem;
            cursor: pointer;
            margin: 2rem;
            transition: all 200ms ease;
            &:hover,
            &:active,
            &:focus {
              color: var(--color-dark-secondary);
            }
          }
        }

        .__name {
          @include offersSliderAnimation;
          margin-top: auto;
          font-size: 1.7rem;
          text-align: center;
          color: var(--color-dark-secondary);
          margin-bottom: min(3rem, 5%);
          height: 6rem;
          @include pulse;
          animation-iteration-count: infinite;
        }

        .__description {
          @include offersSliderAnimation;
          padding: 0 1rem;
          font-size: 1.3rem;
          text-align: center;
          margin-bottom: 2rem;
          height: 13rem;
          overflow-y: auto;
          @media screen and (max-width: 770px) {
            height: 10rem;
          }
        }
      }
    }
    .active {
      display: flex;
    }
  }
}

.tab-switcher {
  display: flex;
  padding: 1rem;
  padding-block: 2rem;
  ul {
    font-size: 1rem;
    width: 700px;
    margin: 0 auto;
    display: flex;
    gap: 2rem;

    justify-content: center;
    align-items: center;

    li {
      opacity: 0.4;
      width: 100%;
      cursor: pointer;
      text-align: center;
      transition: all 200ms ease;
      &.active,
      &:hover {
        opacity: 1;
        padding-bottom: 1rem;
        border-bottom: 2px solid var(--color-primary);
      }
    }
  }
}
