.order-float {
  @include fadeIn;
  width: var(--orderWidth);
  z-index: 0;
  
  margin-bottom: 10rem;
  padding: 1rem;
  background-color: var(--color-primary);
  color: var(--color-light-text);
  border-radius: 15px;
  position: sticky;
  top: 7rem;
  right: 1rem;
  @media screen and (max-width: 1150px) {
    position: static;
    width: 700px;
    max-height: max-content;
  }
  box-shadow: -1px 5px 10px -7px #000000;
  a {
    display: block;
  }
  .__closed {
    font-weight: bold;
    font-size: 1.3rem;
    margin: 0 auto;
    display: block;
    text-align: center;
  }
  .__confirm {
    &:hover {
      font-weight: bold;
      transform: scale(1.05);
    }
  }
  &.--checkout {
    margin-bottom: 1rem;
    margin-top: 0;
    @media screen and (max-width: 1200px) {
      order: 0;
      position: static;
      width: 600px;
    }
  }
  &.--menu {
    @media screen and (max-width: 1150px) {
      display: none;
    }
  }
}

.order {
  > .__header {
    padding-bottom: 0.5rem;
    border-bottom: 1px dashed var(--color-dark-secondary);
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .__text {
      font-size: 1.2rem;
      font-weight: bold;
    }
    > .__type {
      font-size: 1.1rem;
      cursor: pointer;
      transition: all 200ms ease;
      text-decoration: underline;
      &:hover,
      &:focus {
        transform: scale(1.1);
      }
    }
    .__clear {
      font-size: 1.1rem;
      text-decoration: underline;

      cursor: pointer;
    }
  }

  > .__items {
    max-height: 45vh;
    overflow-y: auto;
    margin: 0.5rem auto;
    box-shadow: inset 1px -19px 10px -22px #000000;
    .__placeholder {
      text-align: center;
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 1rem;
      > p {
        margin-bottom: 1rem;
      }
      > img {
        width: 15rem;
      }
    }
    > .__item {
      padding: 0.5rem;
      border-bottom: 1px solid var(--color-dark-secondary);
      * {
        margin-bottom: 0.3rem;
      }
      position: relative;
      padding-bottom: 1.8rem;
      .__controls {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: absolute;
        bottom: 0;
        right: 0;

        > span {
          margin-right: 0.5rem;
          font-weight: bold;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .__total {
        font-weight: bold;
        font-size: 0.9rem;
      }
      .__name {
        font-weight: bold;
        font-size: 1rem;
      }
      .__size {
        font-weight: bold;
        font-size: 0.9rem;
      }
      .__quantity {
        font-weight: bold;
        font-size: 0.9rem;
      }
      .__group-name {
        font-weight: bold;
        font-size: 0.9rem;
      }
      .__addons-list {
        list-style: disc;
        padding: 0 1.3rem;
      }
    }
  }
  .__invoice {
    margin-top: 1rem;
    li {
      display: flex;

      span {
        display: block;
        width: 10rem;
      }
      margin-bottom: 1rem;
    }
  }
  .__delivery-price {
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    font-size: 0.9rem;
    &.--reducted {
      text-decoration: line-through;
    }
  }
  .__tax-seal {
    margin-bottom: 0.5rem;
    font-size: 0.85rem;
    text-align: center;
  }
  .__price-loading {
    margin-top: 1rem;
    display: block;
    color: var(--color-light-text);
    text-align: center;
    margin-bottom: 0.5rem;
  }
  .__order-price {
    text-align: center;
    margin-bottom: 1rem;
    font-weight: bold;
    font-size: 1.3rem;
    font-weight: bolder;
  }
  .__show-coupon {
    text-align: center;
    font-weight: bolder;
    text-decoration: underline;
    margin: 1rem auto;
    font-size: 1.2rem;
    * {
      cursor: pointer;
    }
  }
  .__hide-coupon {
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
    * {
      cursor: pointer;
    }
  }
  .__coupon {
    width: 100%;
    font-weight: bolder;
    input {
      color: var(--color-dark-text);
      font-weight: bolder;
      width: 100%;
      text-align: center;
      border-radius: 10px;
    }
    label {
      width: 100%;
      text-align: center;
    }
  }
  .__qr-scan-icon {
    display: block;
    margin: 0.5rem ;
    margin-left: auto;
    font-size: 1.5rem;
    cursor: pointer;
    
  }
}
