.footer-container {
  min-height: 40vh;
  background-color: var(--color-dark-primary);
  display: flex;
  flex-direction: column;
  .__seo-pages {
    min-height: 10vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    ul {
      li {
        a {
          color: var(--color-light-text) !important;
          text-decoration: underline !important;
          display: block;
          min-width: 10ch;
          margin: 0.3rem;
          text-align: center;
          font-size: 0.9rem;
        }
      }
      margin-bottom: 2rem;
      width: 1000px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  & .__footer {
    min-height: 35vh;
    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      padding-top: 2rem;
    }

    & .__main-column {
      width: 33%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      @media screen and (max-width: 768px) {
        margin-bottom: 2rem;
        width: 100%;
      }

      & .__title {
        font-size: 1.2rem;
        font-weight: bold;
        color: var(--color-light-text);
        margin-bottom: 1rem;
        text-align: center;
      }

      & .__logo {
        max-width: 7rem;
      }
      & .__btn {
     
        margin-bottom: 1rem;
      }
    }
    & .__column {
      width: 33%;
      color: var(--color-light-text);
      display: flex;

      @media screen and (max-width: 768px) {
        width: 50%;
        margin-bottom: 2rem;
        display: flex;
        justify-content: center;
        text-align: center;
      }

      & .__links {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        @media screen and (max-width: 768px) {
          gap: 0.5rem;
        }
        & .__link-container {
          font-size: 1.1rem;
        }
        & .__link {
          text-decoration: none;
          color: unset;
          transition: all 0.3s ease-in-out;

          &:hover {
            color: var(--color-secondary);
          }
        }
      }
    }
  }
  & .__end-banner {
    color: var(--color-light-text);
    display: flex;
    justify-content: center;
    

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }

    & .__copyright {
      display: flex;
      justify-content: center;
      text-align: center;
      min-width: 50%;

      p {
        color: var(--color-secondary);
      }
      @media screen and (max-width: 768px) {
        margin-bottom: 0.5rem;
      }
    }

    
  }
  .__orderset {
    display: flex;
    flex-direction: row;

    justify-content: center;
    align-items: center;
    min-width: 7rem;
    max-height: 4rem;
    & .__a {
      max-height: 4rem;
    }
    & .__img {
      margin-left: 1rem;
      max-height: 4rem;
    }
  }
}

.social-links {
  @include flex(row, flex-end, center, nowrap);
  margin:  1rem;

  @media screen and (max-width: 768px) {
    margin: unset;
    margin-bottom: 1rem;
    justify-content: center;
  }

  .__link {
    @include flex();
    min-width: 2rem;
    min-height: 2rem;

    margin-right: 0.5rem;

    background-color: var(--color-light-text);

    border-radius: 5px;

    transition: filter 0.2s ease;
    &:last-child {
      margin-right: 0;
    }

    a {
      width: -moz-fit-content;
      width: fit-content;
      height: -moz-fit-content;
      height: fit-content;

      color: var(--color-primary);
      font-size: 1.5rem;
      line-height: 0;
      text-decoration: none;
    }
    &:hover {
      a {
        color: var(--color-dark-primary);
      }
    }
  }
}