.categories-bar {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  z-index: 10;
  box-shadow: -1px 5px 10px -7px #000000;
  position: sticky;
  left: 0;
  top: 0;
  min-width: 100vw;
  max-height: 4rem;
  overflow-x: auto !important;
  white-space: nowrap;
  background-color: var(--color-dark-secondary);
  scroll-behavior: smooth;

  > .__list {
    &.--marquee {
      @include marquee;
    }
    padding: 1rem;
    font-size: 0.9rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    > .__item {
      > a {
        color: var(--color-dark-text);
      }
      .__link {
        transition: all 0.2s linear;
        @media screen and (min-width: 768px) {
          &:hover {
            text-decoration: underline !important;
          }
        }

        &.active {
          font-weight: bold;
          font-size: 1.15rem;
        }
      }
      margin-right: 1rem;
      cursor: pointer;
    }
    @media screen and (max-width: 1100px) {
      justify-content: flex-start;
    }
  }
}
