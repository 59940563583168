.modal {
  width: 700px;
  max-width: 100%;
  border-radius: 2rem;
  min-height: 60vh;
  margin: 2rem 0;
  @include boxBackground;

  @media screen and (max-width: 600px) {
    min-height: 100vh;
    border-radius: 0;
    margin: 0;
  }
  .error {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    color: var(--color-primary);
  }

  > .__image {
    width: 100%;
    text-align: center;
    > img {
      border-radius: 15px;
      overflow: hidden;
      margin-bottom: 1rem;
      width: 20rem;
    }
  }
  > .__header {
    top: 0;
    left: 0;
    margin-bottom: 0.5rem;
    padding: 0.3rem;
    min-height: 2.3rem;
    border-bottom: 2px solid var(--color-primary);
    width: 100%;
    font-size: 1.4rem;
    font-weight: 400;

    @media screen and (max-width: 768px) {
      padding-right: 3rem;
    }
  }

  & .__description {
    padding: 0.2rem;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  & .__price {
    font-size: 1rem;
    font-style: italic;
    margin: 0.5rem;
    font-weight: bold;
  }

  & .__total-price {
    margin: 0 auto;
    font-weight: bolder;
  }

  & .__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: 1px solid var(--color-primary);
    padding: 1rem;
    border-radius: 15px;
  }

  & .__sizes {
    margin-bottom: 0.5rem;
    border-bottom: 1px dashedvar(--color-primary);
    padding-bottom: 0.5rem;
    & .__header {
      font-weight: bolder;
      font-size: 1.3rem;
      margin-bottom: 0.4rem;
    }
  }

  & .__addon {
    margin-bottom: 0.5rem;
    padding-bottom: 0.5rem;
    & .__header {
      font-size: 1.1rem;
      font-weight: bolder;
      margin-bottom: 0.4rem;
    }

    & .__body {
      > .checkboxeslist {
        display: flex;
        flex-direction: column;
        gap: 0.3rem;
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: space-around;
    margin-top: 0.1rem;

    label,
    input {
      margin-top: 0.1rem;
    }
  }
  & .__notes {
    min-width: 60%;
    min-height: 5rem;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
    padding: 0.5rem;
    font-family: "heebo", sans-serif;

    &:focus {
      outline: 0;
      border: 2px solid var(--color-primary);
      border-radius: 5px;
    }
  }

  & .__quantity {
    font-size: 1.2rem;
    margin: 0 auto;
    margin-bottom: 1rem;
    text-align: center;
    .__controls {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    button {
      font-size: 1.3rem;
      margin: 0.5rem;
      width: 3rem;
    }
    label {
      margin-right: 0 0.8rem;
    }
    input {
      padding: 0.4rem;
      width: 5rem;
      &:focus {
        outline: 0;
        border: 2px solid var(--color-primary);
        border-radius: 5px;
      }
    }
  }
  .__text-content {
    padding: 1rem;
    font-size: 1rem;
    line-height: 1.3rem;
    > h2 {
      font-size: 1.7rem;
      margin-bottom: 1rem;
      font-weight: bold;
    }
  }
}
.btn-container {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.modal-btn {
  margin-bottom: 1rem;
  border-radius: 50px;
  background: var(--color-buttons);
  white-space: nowrap;
  padding: 0.7rem 1.5rem;
  color: var(--color-dark-text);
  font-size: 1rem;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  text-align: center;
  &:hover {
    transition: all 0.2s ease-in-out;
    filter: brightness(90%);
    transform: scale(1.06);
  }
}

.__link {
  cursor: pointer !important;
}
