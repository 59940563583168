.search {
  z-index: 5;
  width: min(90%, 38rem);
  justify-content: center; 
  margin-left: 1rem;
  display: flex;
  align-items: center;
  * {
    z-index: 10;
  }
  .__bar {
    width: min(90%, 38rem);
  }
 
}
