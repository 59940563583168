.type-selector {
  @include fadeIn;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  min-height: 80vh;
  padding: 1rem;
  
  form {
   @include boxBackground ;
    padding: 3rem 2rem;
    border: 2px solid var(--color-primary);
    border-radius: 15px;
    box-shadow: -1px 5px 10px -7px #000000;
    font-size: 1.1rem;
    width: 600px;
    max-width: 90%;
    text-align: center;
    position: relative;

    .__opening-bar {
      @include flex();
      border-radius: 15px 15px 0px 0px;
      position: absolute;
      width: 100%;
      text-align: center;
      background-color: var(--color-dark-secondary);
      top: 0;
      left: 0;
      font-size: 0.9rem;
      padding: 0.3rem;
      .__dot {
        color:var(--color-primary);
        opacity: 1;
        @include flash();
      }
      &.--red {
        background-color:var(--color-primary);
        color: var(--color-light-text);
      }
    }

    .__input {
      margin-bottom: 0.5rem;
      label {
        margin-right: 1rem;
      }
    }
    .__header {
      font-size: 1.5rem;
      text-align: center;
      margin-bottom: 1rem;
      font-weight: bold;
    }
    .__icon {
      margin-bottom: 1rem;
      width: 5rem;
      height: 5rem;
      color:var(--color-primary);
    }
    .__selector {
      margin: 0.5rem auto;
      margin-bottom: 2rem;
    }
    .__switcher {
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      display: flex;
      margin-bottom: 1rem;
    }
    .btn {
      a {
        color: var(--color-dark-text) !important;
        font-size: 1.1rem;
      }
    }
    .__tablet {
      @include checkbox-with-icon;
      &.--disabled {
        margin-bottom: 2rem;
        cursor: not-allowed;
        position: relative;
        opacity: 0.5;
        &:hover {
          box-shadow: none;
        }
        &::after {
          
          bottom: -2rem;
          opacity: 1;
          content: 'Geschlossen';
          font-weight: bolder;
          
          position: absolute;
          padding: 0.5rem;
        }
      }
    }
  }
}
