.alert-message {
  background-color: var(--color-secondary);
  text-align: center;
  padding: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  &.--error {
    background-color: var(--color-dark-primary);
    color: var(--color-light-text);
    .__icon {
      margin-right: 0.2rem;
      color: var(--color-secondary);
    }
  }
}

.dine-in-alert-message {
  gap: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  p {
    font-size: 1.2rem;
    font-weight: bolder;
    text-align: center;
  }
  .__btns {
    display: flex;
    gap: 0.5rem;
  }
  button {
    width: 7rem;
    font-size: 1rem;
    margin: 0;
  }
}
