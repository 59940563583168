.checkbox {
  > input {
    margin-right: 0.5rem;
  }
  &__list {
    width: 100%;
    &.--grid {
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-wrap: wrap;
    }
  }
  &.--grid {
    flex: 0 0 50%;
    width: 1rem;
    & .__input {
      height: 80%;
      &:hover {
        -webkit-box-shadow: -1px 5px 18px -7px #000000;
        box-shadow: -1px 5px 18px -7px #000000;
      }
      border-radius: 10px;
      margin: 0.8rem;
      margin-top: 0;
      margin-right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      word-wrap: break-word;

      padding: 0.5rem 1rem;
      border: 1px solid var(--color-primary);
      transition: all 200ms ease;
      cursor: pointer;
      &.--checked {
        color:var(--color-light-background);
        background-color:var(--color-primary);
      }
      &.--disabled {
        opacity: 0.4;
        cursor: not-allowed;
        &:hover {
          box-shadow: none;
        }
      }
    }
  }
}

.input {
  margin-bottom: 0.7rem;
  font-size: 1rem;
  width: 99%;
  

  label {
    display: inline-block;
    width: 30%;
    margin-bottom: 0.3rem;
  }
  input,
  select {
    background-color: #fff;
    display: inline-block;
    width: 50%;
    padding: 0.6rem;
    border-radius: 5px;
    outline: 0;
    border: 1px solid rgb(172, 170, 170);
    padding: 0.5rem;
    &:focus {
      border: 1px solid var(--color-primary);
    }
  }
  @media screen and (max-width: 400px) {
    input,
    label,
    select {
      display: block;
      width: 100%;
    }
  }

  &.--checkbox {
    > label {
      display: inline;
      width: unset;
    }
    > input {
      display: inline;
      margin-right: 0.5rem;
      width: unset;
    }
  }

  textarea {
    min-width: 100%;
    min-height: 5rem;
    margin-bottom: 1rem;
    padding: 0.5rem;
    font-family: inherit;
    outline: 0;
    border: 1px solid rgb(172, 170, 170);
    &:focus {
      outline: 0;
      border: 2px solid var(--color-primary);
      border-radius: 5px;
    }
  }
}
