.checkout-wrapper {
  @media screen and (max-width: 1200px) {
    order: 1;
  }
  @include fadeIn;
  z-index: 100;
}

.checkout {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  .__payment-selector {
    margin-bottom: 1rem;
    .__header {
      font-size: 1.2rem;
      text-align: center;
      margin-bottom: 1rem;
      font-weight: bold;
    }
    .__payment {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .__messages {
        * {
          font-size: 1.3rem !important;
        }
        text-align: center;
      }
    }
  }
  .__paypal {
    margin: 1rem auto;

    width: 80%;

    &.--hide {
      max-height: 0;
      overflow: hidden;
      pointer-events: none;
    }
  }
  .__tablet {
    @include checkbox-with-icon;
  }
  > .__form {
    @include checkoutBox;

    > .__group {
      margin-bottom: 1rem;
      border-bottom: 2px dashedvar(--color-primary);
      display: flex;
      flex-direction: column;
      .__header {
        font-size: 1.2rem !important;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }
      .__delivery_info {
        .__section {
          margin-bottom: 1rem;
        }
      }
    }

    .__accept-terms {
      display: inline;
      > li {
        display: inline;
        padding: 0 0.1rem;
        color: blue;
        text-decoration: underline;
      }
    }
    .__login {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    .__btn-container {
      width: 100%;
      display: flex;
      justify-content: center;
      .btn {
        width: 80%;
        font-size: 1.4rem;
        margin: 0.5rem auto;
      }
    }
  }
}

.order-gifts {
  @include checkoutBox;
  margin-bottom: 1rem;
  h2 {
    font-size: 1.8rem !important;
    text-align: center;
  }
  p {
    text-align: center;
  }

  .__gift {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    padding: 1rem;
    min-height: 4rem;
    border: 1px solid var(--color-primary);
    cursor: pointer;
    transition: all 200ms ease;
    margin-top: 0.5rem;
    border-radius: 10px;
    .__from,
    .__to {
      @include pulse;
      animation-iteration-count: infinite;
    }

    & .__image {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 200ms ease;
      min-width: 5rem;
      width: 4rem;
      height: 4rem;
      > img {
        border-radius: 10px;
        overflow: hidden;
        max-width: 100%;
        max-height: 100%;
      }

      &:hover {
        width: 10rem;
        height: 10rem;
      }
    }
    &:hover {
      box-shadow: -1px 5px 10px -7px #000000;
    }
    &.--selected {
      background-color: var(--color-primary);
      color: var(--color-light-text);
    }
    &.--disabled {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}

.checkout-offers {
  @include checkoutBox;
  margin-bottom: 1rem;
  .__title {
    font-size: 1.8rem !important;
    text-align: center;
  }
  ul {
    li {
      margin: 1rem auto;
      padding-bottom: 1rem;
      color: var(--color-dark-text);
      padding: 1rem;
      border-radius: 15px;
      border: 1px solid var(--color-primary);
      background-color: var(--color-dark-secondary);
      p {
        font-size: 1.1rem;
        margin: 0.5rem auto;
        font-weight: bold;
        text-align: center;
        @include pulse;
        animation-iteration-count: infinite;
  
      }
    }
  }
}
