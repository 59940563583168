.nav-wrapper {
  top: 0;
  z-index: 10;
}
.__nav {
  background:var(--color-primary);
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  @media screen and (max-width: 960px) {
    transition: 0.8s all ease;
  }

  .__container {
    display: flex;
    justify-content: space-between;
    height: 4rem;
    z-index: 1;
    width: 100%;
    padding: 0 1.5rem;
    max-width: 1500px;
    @media screen and (max-width: 300px) {
      width: fit-content;
    }
  }

  .__logo {
    max-width: 4rem;
  }
  .__right , .__left{
    display: flex;
    gap: 1rem;
    > * {
      margin-right: 1rem;
    }
  }

  .__logo-wrapper {
    color: var(--color-light-text);
    justify-self: flex-start;
    cursor: pointer;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    font-weight: bold;
    text-decoration: none;
    transition: all 0.3s ease;

    &:hover {
      color: var(--color-secondary);
    }

    @media screen and (max-width: 360px) {
      & .__title {
        font-size: 1rem;
        margin-right: 0.3rem;
      }
      & .Logo {
        width: 3rem;
        margin: 0;
      }
    }
    @media screen and (max-width: 300px) {
      & .__title {
        display: none;
      }
      & .Logo {
        width: 5rem;
      }
    }
  }

  .__mobile-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: var(--color-light-text);
    margin-left: 0.5rem;

    @media screen and (min-width: 920px) {
      display: none;
    }
  }
  .__menu-icon {
    transition: all 0.3s ease;
    &:hover {
      color: var(--color-secondary);
    }
  }
  .__menu {
    display: none;
    font-size: 1.1rem;

    @media screen and(min-width:920px) {
      display: flex;
      align-items: center;
      list-style: none;
      text-align: center;
      margin-right: -22px;
    }
  }
  .__item {
    height: 4rem;
    .__link {
      color: var(--color-light-text);
      display: flex;
      text-decoration: none;
      padding: 0 1rem;
      height: 100%;
      cursor: pointer;
      align-items: center;
      transition: all 0.1s ease-out;

      &:hover {
        border-bottom: 3px solid var(--color-secondary);
      }
    }
  }

  .__btn {
    display: none;

    @media screen and (min-width: 920px) {
      display: flex;
      align-items: center;
    }
    .__btn-link {
      border-radius: 50px;
      background: var(--color-buttons);
      white-space: nowrap;
      padding: 0.7rem 1.5rem;
      color: var(--color-dark-text);
      font-size: 1rem;
      outline: none;
      border: none;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      text-decoration: none;
      text-align: center;

      &.--red {
        box-shadow: -1px 5px 10px -7px #000000;
        background-color:var(--color-primary);
        color: var(--color-light-text);
      }
      &.--green {
        box-shadow: -1px 5px 10px -7px #000000;
      }

      &:hover {
        transition: all 0.2s ease-in-out;
        filter: brightness(90%);
        transform: scale(1.06);
      }
    }
  }
}
