@import url("https://fonts.googleapis.com/css2?family=Cabin:wght@400;600&display=swap");

:root {
  --navbarHeigth: 10vh;
  --orderWidth: 30rem;
  --barHeight: 1rem;
  font-size: 15px;
  @media screen and (max-height: 500px) {
    font-size: 13px;
  }
  @media screen and (max-width: 500px) {
    font-size: 13px;
  }
  @media screen and (max-width: 868px) {
    font-size: 14px;
  }
}
html {
  color: var(--color-dark-text);

  scroll-behavior: smooth;
}
body,
span,
div,
fieldset,
p,
h1,
h2,
h3,
input,
label {
  font-size: 1rem;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  word-wrap: break-word;
  scrollbar-width: auto;
  scrollbar-color: var(--color-primary) #f1da6a00;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--color-primary);
  border-radius: 15px;
  border: 0 solid var(--color-light-secondary);
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
}

a,
a:hover,
a:focus,
a:active {
  text-decoration: none !important;
}

body {
  font-family: "Cabin", sans-serif;
  overflow-x: hidden;
}

p,
h1,
h2,
h3 {
  line-height: 1.2em !important;
  color: inherit;
}

*:disabled {
  opacity: 0.8;
  cursor: not-allowed;
  pointer-events: none;
  cursor: not-allowed !important;
  * {
    opacity: 0.8;
    pointer-events: none;
    cursor: not-allowed !important;
  }
}

.suspense-fallback {
  width: 100vw;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}


.ant-btn {
  border-color: var(--color-buttons);
  color: var(--color-primary);
  &:hover,
  :focus , &:active {
    color: var(--color-primary);
    border-color: var(--color-primary);
  }
}
.ant-btn-primary {
  background: var(--color-buttons) !important;
  border-color: var(--color-primary) !important;
  color: var(--color-light-text) !important;

}


