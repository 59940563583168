.scan {
  width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  min-height: 80vh;
  form {
    padding: 2rem;

    font-size: 1.1rem;

    border: 2px solid var(--color-primary);
    border-radius: 15px;

   @include boxBackground ;
    box-shadow: -1px 5px 10px -7px #000000;
  
    .input {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 1.5rem;
      label {
        width: 100%;
        margin-bottom: 0.5rem;
        text-align: center;
      }
    }
    .btn {
      width: 10rem;
      margin-bottom: 1rem;
    }
    .__qr {
      margin-bottom: 1rem;
    }
  }
}
