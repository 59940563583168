.reservation {
  @include fadeIn;

  min-height: 80vh;
  padding: 2rem;
  width: 800px;
  max-width: 95%;
  margin: 0 auto;
  text-align: center;
  .__header {
    font-size: 2.5rem;
    margin: 2rem auto;
    width: 100%;
    text-align: center;
  }
  .__form {
    @include boxBackground;
    font-size: 1.1rem;
    width: 600px;
    margin: 0 auto;
    max-width: 100%;
    padding: 1rem;
    border: 2px solid var(--color-primary);
    border-radius: 15px;
    box-shadow: -1px 5px 10px -7px #000000;
    margin-bottom: 2rem;

    .__confirm-info {
      margin-top: 1rem;
      
     .input {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      label {
        text-align: left;
      }
     }
      li {
        display: inline;
        padding: 0 0.1rem;
        color: blue;
        text-decoration: underline;
      }
    }

    * {
      width: 100%;
    }
    input {
      margin-bottom: 0.5rem;
    }
    label {
      text-align: center;
      margin-bottom: 0.7em;
    }
    button {
      margin-top: 1rem;
    }
  }
  .__footer {
    text-align: center;
    > * {
      display: block;
      margin-bottom: 0.5rem;
    }
  }
}
