.track {
  @include fadeIn;
  @include checkoutBox;
  min-height: 80vh;
  max-width: 90%;
  margin: 0 auto;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  margin-top: 2rem;

  h1 {
    margin-bottom: 1rem;
    font-size: 1.7rem;
  }
  .__icon {
    width: 10rem;
    height: 10rem;
    color: var(--color-primary);
  }
  form {
    margin-bottom: 2rem;
    text-align: center;

    > .input {
      > input {
        width: 100%;
        padding: 1rem;
        text-align: center;
        font-size: 1.4rem;
      }
    }
    > button {
      font-size: 1.2rem;
      width: 8rem;
    }
  }

  .__total {
    font-size: 1.5rem;
    font-weight: bolder;
    margin-top: 2rem;
  }
  .__not_found {
    font-size: 1.5rem;
  }
  .__result {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 2rem;

    .__title {
      margin-bottom: 0.2rem;
    }
    .__status {
      font-size: 2rem;
      color: var(--color-primary);
      font-weight: bold;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      margin-top: 1rem;
      .__pending {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        p {
          @include pulse;
          animation-iteration-count: infinite;
    
        }
      }
    }

    .__countdown {
      margin-top: 2rem;

      .__counter-title {
        font-size: 1rem;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
      }
      .__time {
        font-size: 2rem;
        padding: 2rem 1rem;
        border-radius: 1rem;
        border: 1px solid var(--color-primary);
        color: var(--color-primary);
      }
    }

    .__complete {
      margin-top: 2rem;
      text-transform: uppercase;
      border-bottom: 1px solid #000;
    }
  }
  @include orderItemsTables;

  .__items {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    padding: 2rem;

    margin-top: 0;
    height: 400px;
    border-radius: 10px;
    border: 1px solid var(--color-primary);

    overflow-y: auto;
  }
}



