.seo-page {
  
    border: 2px solid var(--color-primary);
   @include boxBackground ;
    box-shadow: -1px 5px 10px -7px #000000;
    max-width: 80%;
    min-height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    width: 800px;
    margin: 1rem auto;
    h1 {
        font-size: 2rem;
        margin-bottom: 1rem;
        text-align: center;
        font-weight: bolder;
    }
    p {
        font-size: 1.2rem;
    }
    .__btn {
        .btn {
            padding: 1rem 2rem;
        }
    }
}