.account {
  @include fadeIn;
  > * {
    width: 100%;
    height: 100%;
  }
  min-height: 80vh;
  @include flex(row, space-around, flex-start, $wrap: nowrap, $gap: 1rem);

  @media screen and (max-height: 800px) {
    height: min(140vh, 870px);
  }

  @media screen and (max-width: 786px) {
    flex-direction: column;
    align-items: center;
    height: unset;

    > * {
      width: 95%;
    }
  }
  @media screen and (min-width: 786px) {
    @media screen and (max-height: 600px) {
      min-height: 100vh;
      height: unset;
    }
  }

  margin-bottom: 5rem;
  .__information {
    width: 800px;
    max-width: 90%;
    margin: 1rem 2rem;
    padding: 2rem;

    font-size: 1.1rem;

    border: 2px solid var(--color-primary);
    border-radius: 15px;

    @include boxBackground;
    box-shadow: -1px 5px 10px -7px #000000;

    .__header {
      margin-bottom: 1rem;
      padding-bottom: 1rem;

      font-size: 2rem;

      border-bottom: 1px solid var(--color-primary);
    }
    .__body {
      margin-bottom: 2rem;
      padding-bottom: 1rem;

      font-size: 1.3rem;

      border-bottom: 1px solid var(--color-primary);

      li {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        margin-bottom: 1rem;
        .__key {
          align-self: flex-start;

          margin-bottom: 0.5rem;

          color: var(--color-primary);

          &::after {
            content: ":";
          }
        }
        .__value {
          letter-spacing: 0.12rem;
          * {
            font-family: inherit;
            padding: 0.2rem;
            font-size: 1.2rem;
            svg {
              margin-right: 0.2rem;
            }
          }
        }
      }
    }
    .__password {
      margin-bottom: 2rem;
      padding-bottom: 1rem;

      border-bottom: 1px solid var(--color-primary);
    }
  }

  .__order-history {
    @include flex(column, flex-start, flex-start);
    width: 800px;
    max-width: 90%;
    margin: 1rem 2rem;
    height: 800px;
    padding: 2rem;
    
    border: 2px solid var(--color-primary);
    border-radius: 15px;
    
    @include boxBackground;
    box-shadow: -1px 5px 10px -7px #000000;
    
    .__orders-wrapper {
      width: 100%;
      overflow-y: hidden;
      padding-bottom: 1rem;
      overflow-y: auto;

      .__title {
        @include flex(row, space-between, $wrap: nowrap);
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        
        font-size: 2rem;
        
        border-bottom: 1px solid var(--color-primary);
        
        .__number {
          color: var(--color-primary);
          font-size: 1.5rem;
          text-transform: uppercase;
        }
      }
      
      .__orders {
        max-height: 90%;
        
        &::-webkit-scrollbar-track {
          background: transparent;
        }
        .__order-item {
          @include flex(row, flex-start, $gap: 1rem, $wrap: nowrap);

          @media screen and (max-width: 400px) {
            flex-direction: column;
          }

          &.--preorder {
            background-color: var(--color-secondary);
            padding: 0.5rem;
          }

          margin-bottom: 1rem;
          padding-bottom: 0.5rem;

          border-bottom: 1px solid var(--color-primary);

          .__info {
            width: 80%;
            @media screen and (max-width: 400px) {
              width: 100%;
            }
            .__pair {
              @include flex(row, flex-start, flex-start, nowrap);
              width: 100%;
              margin-bottom: 0.2rem;
              > * {
                width: 100%;
              }
              .__text {
                @include flex(row, space-between);
                i {
                  margin-right: 0.5rem;
                }
              }
            }
          }
          .__view {
            @include flex();
            width: 20%;

            button {
              width: 90%;
              padding: 0.5rem 0;

              color: var(--color-light-text);

              background-color: var(--color-primary);

              border: unset;
              border-radius: 5px;

              cursor: pointer;
              transition: background-color 0.2s ease;

              &:hover {
                background-color: var(--color-dark-primary);
              }
            }
            @media screen and (max-width: 400px) {
              width: 100%;
              button {
                width: 50%;
              }
            }
          }
        }
      }
    }
  }
}
.order-modal {
  min-width: min(70%, 700px);
  min-height: 50%;

  border: 2px solid var(--color-primary);
  border-radius: 15px;
  @include boxBackground;

  box-shadow: -1px 5px 10px -7px #000000;

  .__modal-title {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .__modal-info {
    @include flex(column, flex-start, center, nowrap);
    width: min(90%, 400px);

    margin: 0 auto;

    .__modal-pair {
      @include flex(row, flex-start, flex-start, nowrap);
      width: 100%;

      margin-bottom: 0.5rem;
      padding: 0.5rem 1rem;

      font-size: 1.1rem;
      color: var(--color-light-text);

      border: 1px solid var(--color-dark-primary);
      border-radius: 10px;

      background-color: var(--color-primary);

      > * {
        width: 100%;
      }
      .__modal-text {
        @include flex(row, space-between);
        i {
          margin-right: 0.5rem;
        }
      }
      .__modal-value {
        text-align: right;
      }
      &.__items-title {
        @include flex(row, center, center);
        font-size: 1.3rem;
        margin-top: 1rem;
      }
    }
  }

  @include orderItemsTables;
  .__items {
    margin-top: 0;
    height: 400px;
    border-radius: 10px;
    border: 1px solid var(--color-primary);

    overflow-y: auto;
  }
  @media screen and (max-width: 786px) {
    min-width: 90%;
  }
}

.account-user-review {
  border: 2px solid var(--color-primary);
  border-radius: 15px;
  @include boxBackground;

  box-shadow: -1px 5px 10px -7px #000000;
  max-width: 90%;
  width: 1200px;
  margin: 1rem auto;
  padding: 2rem;
  .btn {
    width: 10rem;
    margin-bottom: 1rem;
  }
  h2 {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 2rem;
    font-weight: bold;
  }
}
