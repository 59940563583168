.cursor-pointer {
  cursor: pointer;
}

.price {
  margin-left: 0.1rem;
  font-size: 1em;
  font-style: italic;
  &::before {
    content: "€";
  }
}

.new-price-wrapper {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0.2rem;
  > * {
    margin: 0.2rem;
  }


  .__old {
    text-decoration: line-through;
    opacity: 0.7;
    &::before {
      content: "€";
    }
  }
  .__new {
    font-weight: bolder;    
    &::before {
      content: "€";
    }
  }
}

.mt-3 {
  margin-top: 0.3rem;
}

.mt-10 {
  margin-top: 1rem;
}
.mb-1 {
  margin-bottom: 1rem;
}

.bold {
  font-weight: bold;
}
.flex-row-end {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.flex-col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.flex-row {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  flex-wrap: wrap;
  margin: 1rem 0;
  @media screen and (max-width: 880px) {
    justify-content: center;
  }
}

.text-light {
  color: var(--color-light-text) !important;
}
.text-center {
  text-align: center !important;
}

.meal-scroll {
  display: block;
  position: relative;
  bottom: 6rem;
  visibility: hidden;
}

.bounce-in {
  @include bounceIn;
}
.bounce-out {
  @include bounceOut;
}
.hide {
  display: none;
}
