.success-screen {
  @include success_after_submit;
  @include boxBackground();
  width: 800px;
  max-width: 90%;
  margin: 1rem auto;
  border: 2px solid var(--color-primary);
  border-radius: 15px;
  box-shadow: -1px 5px 10px -7px #000000;
  h1 {
    font-size: 1.5rem;
    margin: 1rem auto;
  }
  .__slug {
    margin: 2rem auto;
    font-size: 1.5rem;
    font-weight: bolder;
    text-transform: uppercase;
  }
  .__code {
    border: 2px solid var(--color-primary);
    border-radius: 10px;
    padding: 1rem;
    cursor: pointer;
    transition: all 200ms ease;
    &:hover,
    &:focus {
      transform: scale(1.05);
    }
    color: var(--color-primary);
    display: block;
    font-size: 2rem;
    margin: 1rem;

    text-transform: none;
    margin-bottom: 0.3rem;
  }
  .__copy {
    text-transform: none;
    display: block;
    font-size: 1rem;
  }
  .__btns {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 2.5rem;
  }
  .btn {
    padding: 1.1rem;
    max-width: 22rem;
  }
}
