.SidebarContainer {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: var(--color-primary);
  display: grid;
  align-items: center;
  left: 0;
  transition: 0.3s ease-in-out;
  top: 0;
}

.Icon {
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
}

.CloseIcon {
  color: var(--color-light-text);

  transition: all 0.3s ease;
  &:hover {
    color: var(--color-secondary);
  }
}

.SidebarWrapper {
  color: var(--color-light-text);
}

.SidebarMenu {
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  grid-template-rows: repeat(6, 60px);

  @media screen and (min-width: 480px) {
    grid-template-rows: repeat(6, 40px);
  }
  @media screen and (max-height: 700px) {
    grid-template-rows: repeat(5, 40px);
  }
}

.SidebarLink {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  color: var(--color-light-text);
  cursor: pointer;

  &:hover {
    color: var(--color-secondary);
  }
}

.SideBtnWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-bottom: 2rem;

  li {
    padding: 0.5rem;
    text-align: center;
  }
}

.SidebarRoute {
  border-radius: 50px;
  background: var(--color-secondary);
  white-space: nowrap;
  padding: 0.8rem 4rem;
  color: var(--color-dark-text);
  font-size: 16px;
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  margin-bottom: 1rem;

  &:hover {
    background: var(--color-light-background);
  }
}

.__stores-container {
  @include flex(column, flex-start, center, nowrap);
  width: 100%;

  .__title {
    margin-bottom: 1rem;

    color: var(--color-light-text);
  }

  .__store {
    @include flex();
    min-width: 10rem;

    margin-bottom: 0.5rem;
    padding: 0.5rem;

    border-radius: 5px;

    background-color: var(--color-light-text);

    &:last-child {
      margin-bottom: unset;
    }

    a {
      @include flex(row, flex-start, center, nowrap);
      color:var(--color-primary);

      p {
        margin-left: 0.5rem;
      }
    }
  }
}
