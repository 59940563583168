@import "~antd/dist/antd.css";
@import "./base/reset.scss";
@import "./base/variables.scss";
@import "./utils/mixins.scss";
@import "./utils/helpers.scss";
@import "./base/root.scss";
@import "./base/animations.scss";

@import "./layout/cta";
@import "./layout/footer";
@import "./layout/hero";
@import "./layout/loading";
@import "./layout/modal";
@import "./layout/navbar";
@import "./layout/order";
@import "./layout/sidebar";
@import "./layout/categories-bar";
@import "./layout/search";

@import "./Components/buttons";
@import "./Components/inputs";
@import "./Components/validation";
@import "./Components/preorder";
@import "./Components/alert-message";
@import "./Components/datetime";
@import "./pages/maintenance.scss";
@import "./pages/type-selector.scss";
@import "./pages/menu.scss";
@import "./pages/success-screen.scss";
@import "./pages/track.scss";
@import "./pages/checkout.scss";
@import "./pages/account.scss";
@import "./pages/login.scss";
@import "./pages/scan.scss";
@import "./pages/reviews.scss";
@import "./pages/jobs.scss";
@import "./pages/reservation.scss";
@import "./pages/seo.scss";
@import "./pages/home.scss";

@import "./base/unreset.scss";

@import 'node_modules/react-modal-video/scss/modal-video.scss';