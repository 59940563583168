.login,
.signup,
.reset-password {
  @include fadeIn;

  min-height: 80vh;
  padding: 2rem;
  width: 500px;
  margin: 0 auto;
  .__header {
    font-size: 2.5rem;
    margin: 2rem auto;
    width: 100%;
    text-align: center;
  }
  .__confirm-info {
    margin-top: 1rem;
    
   .input {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    label {
      text-align: left;
    }
   }
    li {
      display: inline;
      padding: 0 0.1rem;
      color: blue;
      text-decoration: underline;
    }
  }
  .__form {
   @include boxBackground ;
    font-size: 1.1rem;
    width: 600px;
    max-width: 100%;
    padding: 1rem;
    border: 2px solid var(--color-primary);
    border-radius: 15px;
    box-shadow: -1px 5px 10px -7px #000000;
    margin-bottom: 2rem;

    * {
      width: 100%;
    }
    input {
      margin-bottom: 0.5rem;
    }
    label {
      text-align: center;
      margin-bottom: 0.7em;
    }
    .__remember {
      input {
        width: 8%;
        margin-right: 0.2rem;
      }
    }
  }
  .__footer {
    text-align: center;
    > * {
      display: block;
      margin-bottom: 0.5rem;
    }
  }
}
.signup {
  .__success {
    font-size: 1.5rem;
    text-align: center;
    .__tick {
      color:var(--color-primary);
      font-size: 10rem;
    }
    > * {
      margin-bottom: 1rem;
    }
  }
}

.confirm {
  @include fadeIn;

  min-height: 80vh;
  padding: 2rem;
  width: 500px;
  font-size: 2rem;
  text-align: center;
  margin: 0 auto;
  .__success {
    font-size: 1.5rem;
    text-align: center;
    .__tick {
      color:var(--color-primary);
      font-size: 10rem;
    }
    > * {
      margin-bottom: 1rem;
    }
  }
  .__spinner {
    color:var(--color-primary);
    font-size: 6rem;
  }
}

.reset-password {
  .__success {
    font-size: 1.5rem;
    text-align: center;
    .__tick {
      color:var(--color-primary);
      font-size: 10rem;
    }
    > * {
      margin-bottom: 1rem;
    }
  }
}