.btn {
  display: block;
  margin: 0 auto;
  width: 100%;
  padding: 0.5rem;
  background-color: var(--color-buttons);
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  font-weight: normal;
  transition: all 200ms ease;
  text-align: center;
  color: var(--color-dark-text);
  font-size: 1.2rem;
  &:focus,
  &:hover {
    filter: brightness(90%);
    color: var(--color-dark-text) !important;
  }
  &.--disabled,
  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
}

.order-now {
  margin: 1rem auto;
  text-transform: uppercase;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  > .__btn {
    border-radius: 50px;
    background: var(--color-buttons);
    white-space: nowrap;
    padding: 0.6rem 2rem;
    color: var(--color-dark-text);
    font-size: 1.3rem;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    text-align: center;
    font-weight: bold;
    &:hover {
      filter: brightness(90%);
      transform: scale(1.06);
    }
  }
  .__promo-video {
    cursor: pointer;
    font-weight: bolder;
    text-decoration: underline;
  }
}

#modal-btn {
  text-transform: uppercase;
}

.control {
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 200ms ease;
  display: flex;
  justify-content: center;
  align-items: center;
  * {
    margin-left: 0.5rem;
  }
  .__icon {
    width: 2.5rem;
    height: 2.5rem;
    color: var(--color-primary);
  }
  &:hover {
    transform: scale(1.1);
  }
}

.goto-top {
  position: fixed;

  bottom: 0.5rem;
  right: 0;
  z-index: 100;
  background-color: var(--color-primary);
  border-radius: 50%;
  padding: 1rem;
  box-shadow: -1px 7px 20px -7px #000000;

  width: 4rem;
  height: 4rem;
  color: var(--color-light-text) !important;
  text-align: center;
  .__icon {
    color: var(--color-light-text) !important;
    width: 1.7rem;
    height: 1.7rem;
    transition: all 200ms ease;
  }
  &:hover,
  &:focus {
    .__icon {
      width: 1.7rem;
      height: 1.7rem;
      transform: scale(1.3);
    }
  }
  @media screen and (max-width: 1150px) {
    bottom: 4rem;
  }
}
