.job-submitted {
  @include success_after_submit;
    
}

.jobs-page-container {
  @include flex(column, center, center, unset, unset);
  width: 100%;
  min-height: 90vh;

  .__no-jobs {
    margin: 2rem;
    font-size: 2rem;
    text-align: center;
  }
  .__page-title {
    max-width: 90%;
    
    margin: 2rem 0 3rem;

    font-size: 3rem;
    color: var(--color-dark-text);
    text-align: center;
  }

  .__form-container {
    
    width: max(500px, min(40%, 700px));
    min-height: 50vh;

    padding: 1rem 1.5rem;
    margin-bottom: 3rem;

    @include boxBackground;

    border: 2px solid var(--color-primary);
    border-radius: 15px;

    @media screen and (max-width: 520px) {
      width: 90%;
    }
    form {
      @include flex(column, flex-start, flex-start, nowrap);
      width: 100%;
      .__title {
        margin-bottom: 0.7rem;

        font-size: 1.5rem;
        color: var(--color-dark-primary);
      }

      .__two-inputs {
        @include flex(row, unset, unset, unset);
        width: 100%;

        @media screen and (max-width: 520px) {
          flex-direction: column;
        }
      }
      .__pair {
        @include flex(column, flex-start, flex-start, unset);
        width: 100%;

        margin-bottom: 0.7rem;
        .__label {
          margin-bottom: 0.2rem;
        }

        .__input {
          width: 100%;
          height: 2.5rem;

          padding: 0.2rem 0.5rem;

          border: 1px solid var(--color-dark-text);

          border-radius: 5px;

          &:focus {
            outline: unset;
          }
        }

        .__select-input {
          width: 100%;
          height: 2.5rem;

          padding-right: 5px;

          background-color: var(--color-light-text);
          border: 1px solid var(--color-dark-text);
          border-radius: 5px;

          select {
            width: 100%;
            height: 100%;

            padding: 0.2rem 0.5rem;

            background-color: var(--color-light-text);

            border: unset;
            border-radius: 5px;
            outline: unset;
          }
        }
      }
      h3 + .__pair {
          margin-bottom: 2rem;
      }

      h4 {
        margin-bottom: 1rem;

        font-size: 1.2rem;
        color: var(--color-dark-primary);
      }
      .__half {
        width: 50%;

        &:first-child {
          margin-right: 0.5rem;
        }
          @media screen and (max-width: 520px) {
            width: 100%;
            &:first-child {
              margin-right: unset;
            }
          }
      }
      .__required {
        .__label {
          &::after {
            content: "*";
            display: inline-block;

            margin-left: 0.1rem;
          }
        }

        .__select-input,
        .__input {
          border-color: var(--color-primary);
        }
      }

      .__conditions {
        margin-bottom: 2rem;

        font-size: 0.8rem;
        &::before {
          content: "*";
          display: inline-block;

          margin-right: 0.2rem;
          font-size: 1rem;
        }
      }

      .__submit {
        
        margin: 0 auto 2rem;
        padding: 0.5rem 1rem;

        color:var(--color-light-text);
        font-size: 1.2rem;
        text-align: center;

        border: unset;
        border-radius: 10px;

        background-color: var(--color-primary);

        cursor: pointer;
        transition: transform 0.2s ease, filter 0.2s ease;

        
        &:hover {
          transform: scale(1.02);
          filter: brightness(90%);
        }
        @media screen and (max-width: 400px) {
          width: 100%;
        }
      }
    }
  }
}
