.serve-time {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  > * {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }
  input {
    max-width: 100%;
  }

  .__label {
    display: block;
    margin-bottom: 0.5rem;
  }
  .__picker {
    width: 20rem;
  }
}
.order-time-switcher {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
  .__header {
    font-size: 1.5rem;
    text-align: center;
    margin-bottom: 1rem;
    font-weight: bold;
  }
  .__switcher {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    > * {
      @include checkbox-with-icon;
    }
  }
}

.serve-time {
  div > div {
    * {
      margin-bottom: 0.2rem;
    }
  }
  p {
    margin-bottom: 0.2rem;
  }
}

