.menu-page-container {
  min-height: 80vh;
  padding: 1rem;
  padding-top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
  & .__menu {
    @include fadeIn;
    width: 700px;
  }
  .__daily-offers {
    cursor: pointer;
    background: var(--color-offers-background);
  }
  .__daily-offer {
    margin: 0 auto;
    border-top: 2px solid var(--color-dark-secondary);
    width: 650px;
    & .__image {
      max-height: 12rem;
      overflow-y: hidden;
      > img {
        width: 100%;
      }
    }
    .__body {
      color: var(--color-light-text);

      text-align: center;
      padding: 2rem;
      line-height: 1.2rem;
      h2 {
        @include pulse;
        animation-iteration-count: infinite;
        color: var(--color-light-secondary);
        letter-spacing: 0.2rem;
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }
    }
  }

  & .__category {
    background: linear-gradient(
    191deg,
    var(--color-category-box-1) 0%,
    var(--color-category-box-2) 100%
  );

    margin: 0 auto;
    & .__header {
      & .__image {
        max-height: 24rem;
        overflow-y: hidden;
        > img {
          width: 100%;
        }
      }

      & .__desc {
        padding: 0.5rem 1rem;
        font-size: 1rem;
        color: rgb(75, 73, 73);
      }
    }
    & .__body {
      > ul {
        list-style: none;
      }
      padding: 1rem;
    }
    &.--offer {
      .__body {
        overflow: auto;
        max-width: none;
        > ul {
          display: flex;
          overflow-x: auto;
          width: 100%;
          .__meal {
            margin: 1rem;
          }
        }
      }
      .__meal {
        font-size: 1.4rem;
        min-width: 13rem;
        min-height: 5rem;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 0;

        .__left {
          flex-direction: column;
          width: 100%;

          .__image {
            width: 100%;
            height: 7rem;

            margin-bottom: 1rem;
            overflow: hidden;
          }
          .__name {
            font-size: 1.3rem;
          }
          .__desc {
            font-size: 0.8rem;
            text-align: center;
            width: 100%;
          }
        }
        .__price {
          padding: 1rem;
          width: 100%;
          margin-top: auto;
        }
      }
    }
  }

  & .__meal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    padding: 1rem;
    min-height: 4rem;
    border: 1px solid var(--color-primary);
    margin-bottom: 0.5rem;
    cursor: pointer;
    border-radius: 10px;
    position: relative;
    transition: all 200ms ease;
    overflow: hidden;

    .__name {
      display: flex;
      gap: 0.8rem;
      align-items: center;
      .__additional-info-icon { 
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.05em;
      }
    }
    &.--pulse {
      @include pulse;
    }
    &:hover,
    &:focus {
      box-shadow: -1px 5px 10px -7px #000000;
    }

    & .__name {
      font-weight: bold;
    }

    & .__left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 83%;

      & .__image {
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 200ms ease;
        min-width: 5rem;
        width: 5rem;
        height: 5rem;
        > img {
          border-radius: 10px;
          overflow: hidden;
          max-width: 100%;
          max-height: 100%;
        }

        &:hover {
          width: 10rem;
          height: 10rem;
        }
      }
      & .__info {
        margin-left: 1rem;
        width: 70%;
      }
    }

    & .__price {
      font-size: 0.8rem;
      font-style: italic;
      width: 20%;
      text-align: center;
    }
    & .__desc {
      margin-top: 0.2rem;
      font-size: 0.8rem;
    }

    &.--offer {
      padding-top: 2rem;
      &::after {
        content: "Populär";
        position: absolute;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        top: 0;
        right: 0;
        background-color: var(--color-dark-secondary);
        color: var(--color-dark-text);
        font-weight: 800;
        height: 1.5rem;
        width: 100%;
        text-align: center;
        font-size: 0.9rem;
      }
    }
  }

  @media screen and (max-width: 1100px) {
    justify-content: center;
  }
}
.accordion {
  border: 2px solid var(--color-primary);
  border-radius: 15px;
  overflow-x: hidden;
  margin-bottom: 1rem;
  > .__header {
    background-color: var(--color-primary);
    color: var(--color-light-text);
    min-height: 2.5rem;
    padding: 0.7rem;
    transition: all 200ms ease;

    display: flex;
    justify-content: space-between;
    align-items: center;
    > .__text {
      font-size: 1.2rem;
      font-weight: bold;
      letter-spacing: 0.1rem;
    }
    > .__icon {
      width: 2rem;
      height: 2rem;
      transition: all 300ms ease;
      &.--up {
        transform: rotate(180deg);
      }
    }
    &:hover,
    &:focus {
      filter: brightness(0.8);
      z-index: 0;
    }
  }
  > .__body {
    max-height: max-content;
    transition: max-height 700ms ease, box-shadow 200ms ease;
    overflow: hidden;

    &--collapsed {
      max-height: 0;
      overflow: hidden;
      transition: all 400ms ease;
      .chrome-drag-fix {
        display: none;
      }
    }
  }
}

.mobile-checkout-button {
  position: sticky;
  bottom: 0;
  width: 100%;
  margin: 0 auto;
  background-color: var(--color-primary);
  border-radius: 10px 10px 0px 0px;
  padding: 0.5rem;
  box-shadow: -1px 0px 3px 0px #000000;
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
  color: var(--color-light-background);
  font-size: 1.1rem;

  .__items,
  .__total {
    background-color: var(--color-dark-secondary);
    width: 7rem;
    height: 2rem;
    display: flex;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    color: var(--color-dark-text);
  }

  .__items {
    font-size: 1.5rem;
  }

  @media screen and (min-width: 1180px) {
    display: none;
  }
  @media screen and (max-width: 340px) {
    .__total,
    .__items {
      display: none;
    }
  }

  .btn {
    font-weight: bold;
    width: 10rem;
  }
}

.__dine-in-header {
  text-align: center;
  font-size: 1.3rem;
  margin-top: 2rem;
}


.menu-offers {
  @include checkoutBox;
  width: 100%;
  margin-bottom: 1rem;
  .__title {
    font-size: 1.8rem !important;
    text-align: center;
  }
  ul {
    li {
      margin: 1rem auto;
      padding-bottom: 1rem;
      color: var(--color-dark-text);
      padding: 1rem;
      border-radius: 15px;
      border: 1px solid var(--color-primary);
      background-color: var(--color-dark-secondary);
      p {
        font-size: 1.1rem;
        margin: 0.5rem auto;
        font-weight: bold;
        text-align: center;
        @include pulse;
        animation-iteration-count: infinite;
  
      }
    }
  }
}
