.CTA-container {
  display: flex;
  min-height: 300px;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;

  & .__group {
    display: flex;
    width: 100%;
    & > * {
      width: 100%;
    }
    & .__column {  

      display: flex;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
      text-align: center;

      & .__image {
        font-size: 5rem;
        color:var(--color-primary);
        border: 2px solid var(--color-primary);
        border-radius: 50%;
        padding: 0.5rem;
      }

      & .__title {
        font-weight: bold;
        padding-top: 1rem;
        color:var(--color-primary);
        height: 7rem;
        font-size: 1.3rem;
        text-transform: uppercase;
      }
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    height: unset;
    padding: unset;

    & .__column {
      height: unset;
      margin-bottom: 0.5rem;
      flex-direction: column-reverse;
      & .__title {
        font-size: 1.3rem;        
        padding: 0 0.5rem;
        margin-bottom: 1rem;
        max-width: 90%;
        text-align: center;
      }
    }
  }
}
