.datetime-selector {
  margin: 1rem auto;
  font-size: inherit;
  span {
    svg {
      display: none;
    }
  }
  .timezone {
    &::before {
      content: ' * ';
    }
    font-size: 0.75em;
  }
}
